import { FunctionComponent, useEffect, useState } from "react";
import Moralis from "moralis";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { useMoralis } from "react-moralis";

export const LinkAddress: FunctionComponent = () => {
  const [isLinkFailSnackOpen, setIsLinkFailSnackOpen] = useState(false);
  const [isLinkSuccessSnackOpen, setIsLinkSuccessSnackOpen] = useState(false);
  const [isLinkDialogOpen, setIsLinkDialogOpen] = useState(false);
  const [addressToBeLinked, setAddressToBeLinked] = useState<null | string>(
    null
  );
  const { user } = useMoralis();

  const closeLinkDialog = () => {
    setIsLinkDialogOpen(false);
    setAddressToBeLinked(null);
  };

  const openLinkDialog = (address: string) => {
    setAddressToBeLinked(address);
    setIsLinkDialogOpen(true);
  };

  const closeLinkSuccessSnack = () => {
    setIsLinkSuccessSnackOpen(false);
  };

  const openLinkSuccessSnack = () => {
    setIsLinkSuccessSnackOpen(true);
  };

  const closeLinkFailSnack = () => {
    setIsLinkFailSnackOpen(false);
  };

  const openLinkFailSnack = () => {
    setIsLinkFailSnackOpen(true);
  };

  const linkAddressToCurrentUser = async () => {
    const address = addressToBeLinked;
    closeLinkDialog();

    try {
      await Moralis.Web3.link(address);
    } catch (error) {
      openLinkFailSnack();
      return;
    }

    openLinkSuccessSnack();
  };

  useEffect(() => {
    Moralis.Web3.getSigningData = () =>
      `Login to Trackticat.\n\nIf you sign this, your address will be linked with the current user if it isn't linked with another user already.`;

    Moralis.Web3.onAccountsChanged(async (accounts: string[]) => {
      const linkedAddresses = user.get("accounts");

      if (linkedAddresses.includes(accounts[0])) {
        return;
      }

      openLinkDialog(accounts[0]);
    });
  }, [user]);

  return (
    <>
      <Snackbar
        open={isLinkSuccessSnackOpen}
        autoHideDuration={6000}
        onClose={closeLinkSuccessSnack}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={closeLinkSuccessSnack}
          severity="success"
        >
          Address successfully linked!
        </Alert>
      </Snackbar>
      <Snackbar
        open={isLinkFailSnackOpen}
        autoHideDuration={6000}
        onClose={closeLinkFailSnack}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={closeLinkFailSnack}
          severity="error"
        >
          Unable to link address!
        </Alert>
      </Snackbar>
      <Dialog
        open={isLinkDialogOpen}
        onClose={closeLinkDialog}
        aria-labelledby="link-dialog-title"
        aria-describedby="link-dialog-description"
      >
        <DialogTitle id="link-dialog-title">
          Link address to current user?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="link-dialog-description">
            We detected that you changed the address in MetaMask to one that
            isn't linked to the current user. You have the option here to link
            this address.
            <br />
            <br />
            If the address is already linked to a user this operation will fail.
            <br />
            <br />
            Address to be linked: {addressToBeLinked}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeLinkDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={linkAddressToCurrentUser} color="primary" autoFocus>
            Link
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
