import { FunctionComponent } from "react";
import { useMoralis } from "react-moralis";
import { PortfolioContainer } from "./portfolio/containers/portfolio/portfolio.container";
import { WelcomeContainer } from "./welcome/containers/welcome/welcome.container";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { ProfileContainer } from "./profile/containers/profile/profile.container";
import { PrivateRoute } from "./core/routes/private.route";
import "./app.css";

export const App: FunctionComponent = () => {
  const { isAuthUndefined } = useMoralis();

  if (isAuthUndefined) {
    return null;
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <WelcomeContainer />
        </Route>
        <PrivateRoute path="/portfolio">
          <PortfolioContainer />
        </PrivateRoute>
        <PrivateRoute path="/profile">
          <ProfileContainer />
        </PrivateRoute>
      </Switch>
    </Router>
  );
};
