import { FunctionComponent, useState } from "react";
import styles from "./user-layout.module.css";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useMoralis } from "react-moralis";
import { LinkAddress } from "../link-address/link-address.component";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

export const UserLayout: FunctionComponent = ({ children }) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const { logout } = useMoralis();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handlePortfolio = () => {
    handleClose();
    history.push("/portfolio");
  };

  const handleProfile = () => {
    handleClose();
    history.push("/profile");
  };

  const handleLogout = () => {
    handleClose();
    logout();
    history.push("/");
  };

  return (
    <div className={styles.root}>
      <LinkAddress />
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" className={styles.title}>
            <RouterLink className={styles.logo} to="/">
              Trackticat
            </RouterLink>
          </Typography>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElement}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElement)}
              onClose={handleClose}
            >
              <MenuItem onClick={handlePortfolio}>Portfolio</MenuItem>
              <MenuItem onClick={handleProfile}>Profile</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      {children}
      <footer>
        <Container>
          <Box className={styles.footerContent} pt={2} pb={6}>
            <div>&copy; 2021 Istvan Bodnar. All Rights Reserved.</div>
            <div>
              <Box ml={2} display="inline-block">
                <Link href="https://twitter.com/trackticat" target="_blank">
                  Twitter
                </Link>
              </Box>
              <Box ml={2} display="inline-block">
                <Link href="https://discord.gg/R5wqYfkMFx" target="_blank">
                  Discord
                </Link>
              </Box>
              <Box ml={2} display="inline-block">
                <Link href="https://instagram.com/trackticat" target="_blank">
                  Instagram
                </Link>
              </Box>
              <Box ml={2} display="inline-block">
                <Link href="mailto:hello@trackticat.com" target="_blank">
                  Hello
                </Link>
              </Box>
              <Box ml={2} display="inline-block">
                <Link href="mailto:support@trackticat.com" target="_blank">
                  Support
                </Link>
              </Box>
            </div>
          </Box>
        </Container>
      </footer>
    </div>
  );
};
