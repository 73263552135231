import { FunctionComponent } from "react";
import { UserLayout } from "../../../core/components/user-layout/user-layout.component";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState } from "react";
import { ProfilePanel } from "../../enums/profile-panel.enum";
import { useMoralis } from "react-moralis";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  })
);

export const ProfileContainer: FunctionComponent = () => {
  const classes = useStyles();
  const [selectedPanel, setSelectedPanel] = useState<ProfilePanel | null>(null);
  const { user } = useMoralis();

  const handleChange =
    (panel: ProfilePanel) =>
    (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setSelectedPanel(isExpanded ? panel : null);
    };

  return (
    <UserLayout>
      <Container>
        <Box py={4}>
          <Box mb={4}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="textPrimary">Profile</Typography>
            </Breadcrumbs>
          </Box>
          <div className="root">
            <Accordion
              expanded={selectedPanel === ProfilePanel.USERNAME}
              onChange={handleChange(ProfilePanel.USERNAME)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="username-content"
                id="username-header"
              >
                <Typography className={classes.heading}>Username</Typography>
                <Typography className={classes.secondaryHeading}>
                  {user.get("username")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Currently, you can only look at your username.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={selectedPanel === ProfilePanel.ADDRESSES}
              onChange={handleChange(ProfilePanel.ADDRESSES)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="addresses-content"
                id="addresses-header"
              >
                <Typography className={classes.heading}>
                  Linked addresses
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  List of addresses linked to this user
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {(user.get("accounts") || []).join(", ")}
                  <br />
                  <br />
                  If you want to link a new address to this user, make sure you
                  are logged in with the correct user and switch to the new
                  address in MetaMask, then follow the appearing dialog.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </Box>
      </Container>
    </UserLayout>
  );
};
