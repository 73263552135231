import { useEffect, useState } from "react";
import { Balance } from "../interfaces/balance.interface";
import Moralis from "moralis";
import Big from "big.js";
import { coingeckoService } from "../../coingecko/services/coingecko.service";
import { CoingeckoCoinId } from "../../coingecko/enums/coingecko-coin-id.enum";
import { CoingeckoPlatformId } from "../../coingecko/enums/coingecko-platform-id.enum";

interface Hook {
  balances: Balance[];
  isLoading: boolean;
  totalValue: string;
}

export const useBalances = (currency: string): Hook => {
  const [balances, setBalances] = useState<Balance[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalValue, setTotalValue] = useState("-");

  useEffect(() => {
    setIsLoading(true);
    const accumulator: Balance[] = [];
    let total = new Big(0);
    const getBalances = async () => {
      try {
        const ethBalances: Record<string, any>[] = (
          await Moralis.Web3.getAllERC20({ chain: "eth" })
        ).map((item: Record<string, any>) => ({ ...item, chain: "eth" }));
        const bscBalances: Record<string, any>[] = (
          await Moralis.Web3.getAllERC20({ chain: "bsc" })
        ).map((item: Record<string, any>) => ({ ...item, chain: "bsc" }));
        const polygonBalances: Record<string, any>[] = (
          await Moralis.Web3.getAllERC20({ chain: "polygon" })
        ).map((item: Record<string, any>) => ({ ...item, chain: "polygon" }));

        const nativePrices = await coingeckoService.v3SimplePrice(
          [
            CoingeckoCoinId.ETHEREUM,
            CoingeckoCoinId.BINANCE_COIN,
            CoingeckoCoinId.POLYGON,
          ],
          [currency]
        );
        const ethPrices = await coingeckoService.v3SimpleTokenPrice(
          CoingeckoPlatformId.ETHEREUM,
          ethBalances
            .map((ethBalance) => ethBalance.tokenAddress || null)
            .filter((ethBalance) => ethBalance),
          [currency]
        );
        const bscPrices = await coingeckoService.v3SimpleTokenPrice(
          CoingeckoPlatformId.BINANCE_SMART_CHAIN,
          bscBalances
            .map((bscBalance) => bscBalance.tokenAddress || null)
            .filter((bscBalance) => bscBalance),
          [currency]
        );
        const polygonPrices = await coingeckoService.v3SimpleTokenPrice(
          CoingeckoPlatformId.POLYGON,
          polygonBalances
            .map((polygonBalance) => polygonBalance.tokenAddress || null)
            .filter((polygonBalance) => polygonBalance),
          [currency]
        );

        ethBalances.forEach((ethBalance) => {
          const balance = Big(ethBalance.balance).div(
            Big(10).pow(ethBalance.decimals)
          );
          const price = ethBalance.tokenAddress
            ? ethPrices[ethBalance.tokenAddress]?.[currency]
            : nativePrices[CoingeckoCoinId.ETHEREUM]?.[currency];
          const priceValue = price ? balance.mul(price) : new Big(0);
          accumulator.push({
            name: ethBalance.name,
            symbol: ethBalance.symbol,
            tokenAddress: ethBalance.tokenAddress,
            chain: ethBalance.chain,
            chainValue: 0,
            balance: balance.toString(),
            price: price
              ? new Intl.NumberFormat(
                  new Intl.NumberFormat().resolvedOptions().locale,
                  { style: "currency", currency: currency }
                ).format(balance.mul(price).round(17).toNumber())
              : null,
            priceValue,
          });
          total = total.add(priceValue);
        });
        bscBalances.forEach((bscBalance) => {
          const balance = Big(bscBalance.balance).div(
            Big(10).pow(bscBalance.decimals)
          );
          const price = bscBalance.tokenAddress
            ? bscPrices[bscBalance.tokenAddress]?.[currency]
            : nativePrices[CoingeckoCoinId.BINANCE_COIN]?.[currency];
          const priceValue = price ? balance.mul(price) : new Big(0);
          accumulator.push({
            name: bscBalance.name,
            symbol: bscBalance.symbol,
            tokenAddress: bscBalance.tokenAddress,
            chain: bscBalance.chain,
            chainValue: 1,
            balance: balance.toString(),
            price: price
              ? new Intl.NumberFormat(
                  new Intl.NumberFormat().resolvedOptions().locale,
                  { style: "currency", currency: currency }
                ).format(balance.mul(price).round(17).toNumber())
              : null,
            priceValue,
          });
          total = total.add(priceValue);
        });
        polygonBalances.forEach((polygonBalance) => {
          const balance = Big(polygonBalance.balance).div(
            Big(10).pow(polygonBalance.decimals)
          );
          const price = polygonBalance.tokenAddress
            ? polygonPrices[polygonBalance.tokenAddress]?.[currency]
            : nativePrices[CoingeckoCoinId.POLYGON]?.[currency];
          const priceValue = price ? balance.mul(price) : new Big(0);
          accumulator.push({
            name: polygonBalance.name,
            symbol: polygonBalance.symbol,
            tokenAddress: polygonBalance.tokenAddress,
            chain: polygonBalance.chain,
            chainValue: 2,
            balance: balance.toString(),
            price: price
              ? new Intl.NumberFormat(
                  new Intl.NumberFormat().resolvedOptions().locale,
                  { style: "currency", currency: currency }
                ).format(balance.mul(price).round(17).toNumber())
              : null,
            priceValue,
          });
          total = total.add(priceValue);
        });

        accumulator.sort((a, b) => {
          const aChainValue = a.chainValue;
          const bChainValue = b.chainValue;
          const aPriceValue = a.priceValue;
          const bPriceValue = b.priceValue;

          if (aChainValue < bChainValue) {
            return -1;
          } else if (aChainValue > bChainValue) {
            return 1;
          } else if (aPriceValue.lt(bPriceValue)) {
            return 1;
          } else if (aPriceValue.gt(bPriceValue)) {
            return -1;
          } else {
            return 0;
          }
        });

        setBalances(accumulator);
        setTotalValue(
          new Intl.NumberFormat(
            new Intl.NumberFormat().resolvedOptions().locale,
            { style: "currency", currency: currency }
          ).format(total.round(17).toNumber())
        );
      } catch (error) {
        console.error(
          `Unable to load balances. ${JSON.stringify({
            error: String(error),
          })}`
        );
      } finally {
        setIsLoading(false);
      }
    };
    getBalances();
  }, [currency]);

  return { balances, isLoading, totalValue };
};
