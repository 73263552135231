import axios from "axios";
import { CoingeckoCoinId } from "../enums/coingecko-coin-id.enum";
import { CoingeckoPlatformId } from "../enums/coingecko-platform-id.enum";

class CoingeckoService {
  private readonly baseUrl = "https://api.coingecko.com/api";

  public async v3SimpleSupportedVsCurrencies(): Promise<string[]> {
    const response = await axios.get<string[]>(
      `${this.baseUrl}/v3/simple/supported_vs_currencies`
    );
    return response.data;
  }

  public async v3SimplePrice(
    ids: CoingeckoCoinId[],
    vsCurrencies: string[]
  ): Promise<
    Partial<Record<CoingeckoCoinId, Partial<Record<string, number>>>>
  > {
    const response = await axios.get<
      Record<CoingeckoCoinId, Partial<Record<string, number>>>
    >(`${this.baseUrl}/v3/simple/price`, {
      params: {
        ids: ids.join(","),
        vs_currencies: vsCurrencies.join(","),
      },
    });
    return response.data;
  }

  public async v3SimpleTokenPrice(
    platformId: CoingeckoPlatformId,
    contractAddresses: string[],
    vsCurrencies: string[]
  ): Promise<Partial<Record<string, Partial<Record<string, number>>>>> {
    const response = await axios.get<
      Partial<Record<string, Partial<Record<string, number>>>>
    >(`${this.baseUrl}/v3/simple/token_price/${platformId}`, {
      params: {
        contract_addresses: contractAddresses.join(","),
        vs_currencies: vsCurrencies.join(","),
      },
    });
    return response.data;
  }
}

export const coingeckoService = new CoingeckoService();
