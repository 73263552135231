import { FunctionComponent, useEffect } from "react";
import { useMoralis } from "react-moralis";
import styles from "./welcome.module.css";
import Moralis from "moralis";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";

export const WelcomeContainer: FunctionComponent = () => {
  const { authenticate, isAuthenticated } = useMoralis();
  const history = useHistory();

  useEffect(() => {
    Moralis.Web3.getSigningData = () =>
      `Login to Trackticat.\n\nIf you want to link a new address to your existing user, first login with your existing user and then switch to the new address in MetaMask and follow the appearing dialog.\n\nLogging in with your new address now will create a new user.`;
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/portfolio");
    }
  }, [isAuthenticated, history]);

  if (isAuthenticated) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Box m={2}>
        <Typography variant="h3" component="h1">
          Trackticat
        </Typography>
      </Box>
      <Typography variant="body1">
        You need to login with MetaMask to see your portfolio.
      </Typography>
      <Box m={2}>
        <Typography variant="body1">
          If you want to link a new address to your existing user, first login
          with your existing user and then switch to the new address in MetaMask
          and follow the appearing dialog.
          <br />
          Logging in with your new address now will create a new user.
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={() => authenticate()}
      >
        Login with MetaMask
      </Button>
      <footer>
        <Box m={4}>
          <Box mb={1}>&copy; 2021 Istvan Bodnar. All Rights Reserved.</Box>
          <div>
            <Box mx={1} display="inline-block">
              <Link href="https://twitter.com/trackticat" target="_blank">
                Twitter
              </Link>
            </Box>
            <Box mx={1} display="inline-block">
              <Link href="https://discord.gg/R5wqYfkMFx" target="_blank">
                Discord
              </Link>
            </Box>
            <Box mx={1} display="inline-block">
              <Link href="https://instagram.com/trackticat" target="_blank">
                Instagram
              </Link>
            </Box>
            <Box mx={1} display="inline-block">
              <Link href="mailto:hello@trackticat.com" target="_blank">
                Hello
              </Link>
            </Box>
            <Box mx={1} display="inline-block">
              <Link href="mailto:support@trackticat.com" target="_blank">
                Support
              </Link>
            </Box>
          </div>
        </Box>
      </footer>
    </div>
  );
};
