import { Theme } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { FunctionComponent, useEffect, useState } from "react";
import { coingeckoService } from "../../../coingecko/services/coingecko.service";
import { UserLayout } from "../../../core/components/user-layout/user-layout.component";
import { useBalances } from "../../../core/hooks/use-balances.hook";
import { useCurrency } from "../../../core/hooks/use-currency.hook";
import styles from "./portfolio.module.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

export const PortfolioContainer: FunctionComponent = () => {
  const classes = useStyles();
  const { currency, changeCurrency } = useCurrency();
  const { balances, isLoading, totalValue } = useBalances(currency);
  const [currencies, setCurrencies] = useState<string[]>([]);

  const handleCurrencyChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    changeCurrency(event.target.value as string);
  };

  useEffect(() => {
    coingeckoService
      .v3SimpleSupportedVsCurrencies()
      .then((vsCurrencies) =>
        setCurrencies(
          vsCurrencies.sort((a, b) => {
            if (a < b) {
              return -1;
            } else if (a > b) {
              return 1;
            } else {
              return 0;
            }
          })
        )
      )
      .catch(() => setCurrencies(["usd"]));
  }, []);

  return (
    <UserLayout>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        <Box py={4}>
          <Box className={styles.controls} mb={4}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="textPrimary">Portfolio</Typography>
            </Breadcrumbs>
            {currencies.length > 0 && (
              <Box className={styles.currencySelectWrapper}>
                <FormControl className={styles.currencySelect}>
                  <InputLabel id="currency-select-label">Currency</InputLabel>
                  <Select
                    labelId="currency-select-label"
                    id="currency-select"
                    value={currency}
                    onChange={handleCurrencyChange}
                  >
                    {currencies.map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Box>
          {balances.length > 0 && (
            <>
              <TableContainer component={Paper}>
                <Table aria-label="portfolio" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Chain</TableCell>
                      <TableCell>Symbol</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Balance</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {balances.map((balance) => (
                      <TableRow key={balance.name} hover>
                        <TableCell component="th" scope="row">
                          {balance.chain}
                        </TableCell>
                        <TableCell>{balance.symbol}</TableCell>
                        <TableCell>{balance.name}</TableCell>
                        <TableCell>{balance.balance}</TableCell>
                        <TableCell>
                          {balance.tokenAddress || "Native"}
                        </TableCell>
                        <TableCell>
                          <strong>{balance.price || "-"}</strong>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={6} align="right">
                        <div className={styles.footer}>
                          <div>
                            Powered by{" "}
                            <Link href="https://coingecko.com" target="_blank">
                              CoinGecko
                            </Link>
                          </div>
                          <div>
                            <strong>Total value: {totalValue}</strong>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      </Container>
    </UserLayout>
  );
};
