import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { MoralisProvider } from "react-moralis";
import { coreEnvVar } from "./core/utils/core-env-var";
import { CoreEnvKey } from "./core/enums/core-env-key.enum";
import { App } from "./app";
import CssBaseline from "@material-ui/core/CssBaseline";

ReactDOM.render(
  <React.StrictMode>
    <MoralisProvider
      appId={coreEnvVar(CoreEnvKey.REACT_APP_MORALIS_APP_ID)}
      serverUrl={coreEnvVar(CoreEnvKey.REACT_APP_MORALIS_SERVER_URL)}
    >
      <CssBaseline />
      <App />
    </MoralisProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
