import { useState } from "react";
import { useMoralis } from "react-moralis";

interface Hook {
  currency: string;
  changeCurrency: (currency: string) => void;
}

export const useCurrency = (): Hook => {
  const { user } = useMoralis();
  const [currency, setCurrency] = useState<string>(
    user.get("currency") || "usd"
  );

  const changeCurrency = async (currency: string): Promise<void> => {
    setCurrency(currency);
    try {
      user.set("currency", currency);
      await user.save();
    } catch (error) {}
  };

  return { currency, changeCurrency };
};
